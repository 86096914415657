import * as React from "react";
import { WizardVRPWAProps, WizardVRPWATemplateComponentConfig } from "./typings";
import { inject, observer } from "mobx-react";
import { WizardVRPWAView } from "./WizardVRPWAView";
import { toBooleanOrUndefined } from "components/utility/toBooleanOrUndefined";
import { getQueryParamValue } from "components/utility/UrlUtils";

@inject("wizardState", "page", "cookies")
@observer
export class WizardVRPWA extends React.Component<WizardVRPWAProps> {
  constructor(props: WizardVRPWAProps) {
    super(props);
    const { templateComponent } = props;
    const options = templateComponent.config as WizardVRPWATemplateComponentConfig;
    const vrWizardState = this.props.wizardState.vrWizardState;
    const useRewards = toBooleanOrUndefined(getQueryParamValue("useRewards"));
    vrWizardState.overrideConfig(() => {
      vrWizardState.config.elementId = templateComponent.metadata.id;
      vrWizardState.config.heading.fixedTitle = options.fixedLobTitle;
      vrWizardState.config.heading.lobSubtitleBreakPoint = options.lobSubtitleBreakPoint;
      vrWizardState.config.heading.hideLobTitle = options.hideLobTitle;
      vrWizardState.config.enableVRPWPToggle = options.showVRPWPToggle || useRewards !== undefined;
      if (useRewards !== undefined) {
        vrWizardState.isPayWithPointsChecked = useRewards;
      }
    });
    vrWizardState.updateDateFromConfig();
  }

  public render() {
    return <WizardVRPWAView {...this.props} />;
  }
}

export default WizardVRPWA;
