import { observer } from "mobx-react";
import * as React from "react";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkSwitch } from "@egds/react-core/switch";
import { VRWizardStateProps } from "components/flexComponents/WizardVRPWA/typings";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { useLocalization } from "@shared-ui/localization-context";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

export const VRAddPWPToggle = observer(({ wizardState }: VRWizardStateProps) => {
  const { formatText } = useLocalization();
  const vrWizardState = wizardState.vrWizardState;

  const track = useClickTracker();

  const getTrackingConfig = (isPayWithPointsChecked: boolean): FlexTrackingInfo => {
    const key = isPayWithPointsChecked;

    return {
      moduleName: "wizard-vr-pwa",
      action: Action.CLICK,
      linkName: `${key} wizard v2 expando`,
      rfrr: `pwpToggle.${key}`,
    };
  };

  const handleChange = () => {
    vrWizardState.togglePayWithPointsChecked();
    const trackInfo = getTrackingConfig(vrWizardState.isPayWithPointsChecked);
    sendDelayedClickstreamTrackEvent(trackInfo, track);
  };

  const changeLabelOnToggle = () => {
    if (vrWizardState.isPayWithPointsChecked)
      return formatText(vrWizardState.config.payWithPointsCheckedToken as string);
    return formatText(vrWizardState.config.payWithCashCheckedToken as string);
  };
  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <div data-testid="pwp-toggle">
        <UitkSwitch
          id="pwpToggle"
          checked={vrWizardState.isPayWithPointsChecked}
          onChange={handleChange}
          name="rewards toggle"
          switchPosition="trailing"
          value={String(vrWizardState.isPayWithPointsChecked)}
        >
          {changeLabelOnToggle()}
        </UitkSwitch>
      </div>
    </UitkSpacing>
  );
});
